/**
 * Created by Rochaus on 11/12/2022.
 */
import "../css/index.css";
import React, {useState} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
    const [content, setContent] = useState("");
    return (
        <div>
            <Header/>
            <div>
                <div className={"content"}>
                    {content}
                </div>
                <div className="push"></div>
            </div>
            <Footer/>
        </div>
    );
}
export default Home;
