import "./css/index.css";
import React, {useState} from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./containers/Home";

function App() {
    const [authorized, setAuthorized] = useState(false);
    return (
        <div>
            <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path={"home"} element={<Home/>}/>
            </Routes>
        </div>
    );
}

export default App;
