/**
 * Created by Rochaus on 11/4/2022.
 */
import React from 'react';

const Footer = () => {
    return <footer>
        <div>
            &copy; Copyright 2019. All Rights Reserved. Robert Greathouse.
        </div>
    </footer>;
}
export default Footer;
