/**
 * Created by Rochaus on 11/4/2022.
 */
import React from 'react';

const Header = () => {
    return <header>
        <img src={"https://kismetgreathouse.com/media/images/alara/alara_logo.png"} alt="logo"/>
    </header>;
}
export default Header;
